"use strict";

export default {
    "password": {
        "message": "{_field_}は英数字混合の8文字以上20文字以下で入力してください。",
        validate(value) {
            const rule = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,20}$/i;
            return rule.test(value);
        },
    },
};
